import React from "react";
import "./FaixaVermelha.css"

const FaixaVermelha = () => {
    return(
        <div className="faixa-vermelha">
           
        </div>
    )
}

export default FaixaVermelha