import React from "react";
import "./BannerFaixa.css"

const BannerFaixa = (props) => {
    return(
        <section className="BannerFaixaPai">
        <a className="botaoFaixa" href="/galeria">Galeria de Fotos</a>

        </section>
    )
}
export default BannerFaixa