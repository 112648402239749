import React from "react";
import "./rodape.css"


const Rodape = () => {
    return(
        <section className="rodape">
            <p>Criado por 4set.io</p>
        </section>
    )
}
export default Rodape